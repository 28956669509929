import { Injectable, inject } from '@angular/core';
import { ServiceCall } from './service-call.service';
import { environment } from '@environments/environment';
import { catchError, map, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@app/cde-toast/services/alert.service';
import { TOASTR_ALERT_OPTIONS } from '@app/cde-toast/utils/toastr-alert-options';

export const SUPPORT_URL = 'v1/support';

@Injectable()
export class SupportService {
  private alertService = inject(AlertService);
  private serviceCall = inject(ServiceCall);
  private translate = inject(TranslateService);
  private supportUrl = `${environment.apiBaseUrl}/${SUPPORT_URL}`;

  private alertOptions = {
    ...TOASTR_ALERT_OPTIONS,
    timeOut: 3000,
  };

  createSupportRequest(payload: FormData) {
    return this.serviceCall.post(`${this.supportUrl}/request`, payload).pipe(
      map((response) => response.data),
      catchError((error) => {
        this.alertService.showError(this.translate.instant(error.data.error.localMessageKey), '', 'alert-error-icon', this.alertOptions);
        return throwError(error);
      })
    );
  }
}
