import { Injectable, inject } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { IChangeShopDealerRequest, IDeleteShopRequest, IOrganisationDealer, IRegisterShopRequest, IShop } from '../models/shops.model';
import { ServiceCall } from './service-call.service';
import { IAvailableShop, IRegisteredShop } from 'cde-fe-organization-registration-dialog';
import { environment } from '@environments/environment';

const ORGANIZATION_URL = 'v3/organizations';

// authorized-users is changed to members

@Injectable({
  providedIn: 'root',
})
export class ShopsService {
  private serviceCall = inject(ServiceCall);
  private orgUrl = `${environment.claasIdApiBaseUrl}/${ORGANIZATION_URL}`;
  // get all organization shops
  getOrganisationShops(orgId: string): Observable<IShop[]> {
    if (!orgId) {
      return throwError('invalid org ID');
    }

    return this.serviceCall
      .get(`${this.orgUrl}/${orgId}/shops`)
      .pipe(
        map((response) =>
          response.data.content as IShop[]
        )
      );
  }

  getOrganisationDealer(orgId: string, dealerId: string): Observable<IOrganisationDealer> {
    if (!orgId) {
      return throwError('invalid org ID');
    }

    if (!dealerId) {
      return throwError('invalid dealer ID');
    }

    // services wants dealer ID with leading zeros, otherwise dealer is not
    // found
    dealerId = dealerId.padStart(10, '0');
    return this.serviceCall
      .get(`${this.orgUrl}/${orgId}/dealers/${dealerId}`)
      .pipe(
        map((response) => ({
          ...response.data as IOrganisationDealer,
          // use input dealer ID to be consistent
          dealerId: dealerId
        }))
      );
  }

  unregisterShop(deleteRequest: IDeleteShopRequest) {
    return this.serviceCall
      .delete(`${this.orgUrl}/${deleteRequest.organisationId}/shops/${deleteRequest.shopId}`)
      .pipe(
        map((response) => deleteRequest)
      );
  }

  getConnectedDealers(orgId: string): Observable<IOrganisationDealer[]> {
    if (!orgId) {
      return throwError('invalid org ID');
    }

    return this.serviceCall
      .get(`${this.orgUrl}/${orgId}/connected-dealers`)
      .pipe(
        map((response) => response.data as IOrganisationDealer[])
      );
  }

  registerShop(registerShopRequest: IRegisterShopRequest) {
    const { organisationId, shop } = registerShopRequest;

    return this.serviceCall
      .post(`${this.orgUrl}/${organisationId}/shops`, shop)
      .pipe(
        map((response) => response.data as IRegisteredShop)
      );
  }
}
